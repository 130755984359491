<template>
	<div>
		<div
			v-show="loading"
			class="grid w-full grid-cols-1 gap-16 py-16 text-xl font-bold text-center"
		>
			<div class="w-full text-gray-300">
				Loading...
			</div>
			<LoadingSpinner class="w-1/4 mx-auto text-gray-300 md:w-1/12" />
		</div>
		<div v-show="!loading">
			<slot name="header" />
			<div class="relative w-full">
				<Transition name="fade">
					<slot name="previous">
						<div
							v-show="showLeftArrow && hasMultipleSlides"
							:class="[
								'absolute z-20 transform -translate-x-1/2 -translate-y-1/2 top-1/2',
								arrowVisibility,
								leftArrowPosition
							]"
						>
							<button
								class="transition-all duration-100 ease-in-out border-0 border-none rounded-full"
								:class="[
									arrowOffsetClasses,
									arrowClasses,
									arrowPreviousOffset
								]"
								@click="scrollLeft()"
							>
								<span
									:class="[
										arrowSizeClasses,
										arrowColor,
										'flex content-center justify-center text-4xl font-bold text-center align-middle'
									]"
								>
									<img
										:src="arrowRight"
										:height="20"
										:width="20"
										alt="Previous Arrow"
										class="my-auto transform rotate-180 h-2/3"
									>
								</span>
							</button>
						</div>
					</slot>
				</Transition>
				<Transition name="fade">
					<slot name="next">
						<div
							v-show="showRightArrow && hasMultipleSlides"
							:class="[
								'absolute z-20 transform -translate-x-1/2 -translate-y-1/2 top-1/2',
								arrowVisibility,
								rightArrowPosition
							]"
						>
							<button
								class="transition-all duration-100 ease-in-out border-0 border-none rounded-full"
								:class="[
									arrowClasses,
									arrowOffsetClasses,
									arrowNextOffset
								]"
								@click="scrollRight()"
							>
								<span
									:class="[
										arrowSizeClasses,
										arrowColor,
										'flex content-center justify-center text-4xl font-bold text-center align-middle'
									]"
								>
									<img
										:src="arrowRight"
										:height="20"
										:width="20"
										alt="Next Arrow"
										class="my-auto h-3/4"
									>
								</span>
							</button>
						</div>
					</slot>
				</Transition>
				<WwSideScroller
					ref="carousel"
					:fade-visibility="fadeVisibility"
					:item-spacing="itemSpacing"
					:scroller-class="combinedScrollClasses"
					:gradient-color-from="gradientColorFrom"
					:gradient-width="gradientWidth"
					:fade-use-row-item-height="fadeUseRowItemHeight"
					@mouseover.native="pauseAutoplay"
					@mouseout.native="resumeAutoplay"
				>
					<slot name="slides" />
				</WwSideScroller>
				<slot
					v-if="!hideFooter"
					name="footer"
					:set-slide="setSlide"
					:previous="previous"
					:next="next"
				>
					<div
						v-if="hasMultipleSlides"
						class="absolute flex justify-center w-full space-x-3 transform -translate-x-1/2 -bottom-8 xl:flex left-1/2"
						:class="footerClass"
					>
						<button
							v-for="index in slideCount"
							:key="'dot-' + index"
							:class="[
								'flex justify-center border-none rounded-full flex-nowrap transition-all duration-300 ease-in-out focus:rounded-full',
								{ 'ring-offset-2': !noBulletRingOffset },
								bulletOuterClasses
							]"
							title="Slide Control Button"
							@click="setSlide(index, forceBulletAction)"
							@keyup.left="previous(forceBulletAction)"
							@keyup.right="next(forceBulletAction)"
						>
							<span class="relative w-4 h-4 rounded-full">
								<span
									:class="[
										index - 1 === activeSlide ? ' bg-green-500' : 'bg-gray-300',
										{ 'ring-offset-2': !noBulletRingOffset },
										bulletInnerClasses,
										'absolute top-0 left-0 z-10 w-4 h-4 border-none rounded-full transform transition-all duration-300 ease-in-out ring-transparent ring-2'
									]"
								/>
								<span
									:class="[
										index - 1 === activeSlide ? ' bg-gray-300 scale-0' : 'bg-gray-300 scale-100',
										{ 'ring-offset-2': !noBulletRingOffset },
										bulletInnerClasses,
										'absolute top-0 left-0 z-20 w-4 h-4 border-none rounded-full transform transition-all duration-300 ease-in-out ring-transparent ring-2'
									]"
								/>
							</span>
						</button>
					</div>
				</slot>
			</div>
		</div>
	</div>
</template>

<script async>
import Bugsnag from '@bugsnag/js'

import LoadingSpinner from '@/components/multiUse/LoadingSpinner.vue'
import WwSideScroller from '@/components/UI/WwSideScroller.vue'
import CAROUSEL_ITEM from '@/constants/carousel/carousel-item.js'
import SIDE_SCROLLER from '@/constants/UI/side-scroller.js'
import { logError } from '@/utils/error-handling.js'

export default {
	components: {
		WwSideScroller,
		LoadingSpinner
	},
	props: {
		itemCount: {
			type: Number,
			required: true
		},
		itemsPerSlide: {
			type: Number,
			default: 1
		},
		itemSpacing: {
			type: String,
			default: 'space-x-0'
		},
		hideFooter: {
			type: Boolean,
			default: false
		},
		fadeVisibility: {
			type: String,
			default: ''
		},
		footerClass: {
			type: String,
			default: 'py-6'
		},
		arrowVisibility: {
			type: String,
			default: ''
		},
		arrowSizeClasses: {
			type: String,
			default: 'w-10 h-10'
		},
		bulletOuterClasses: {
			type: String,
			default: 'focus:border-gray-300 ring-transparent focus:ring-green-300 hover:ring-green-300 focus:ring-2'
		},
		bulletInnerClasses: {
			type: String,
			default: 'hover:ring-green-300'
		},
		arrowClasses: {
			type: String,
			default: 'ring-offset-2 hover:ring focus:ring ring-green-300 focus:border-green-300 bg-green-500'
		},
		arrowNextOffset: {
			type: String,
			default: ''
		},
		arrowPreviousOffset: {
			type: String,
			default: ''
		},
		arrowColor: {
			type: String,
			default: 'text-white'
		},
		sideScrollerClass: {
			type: String,
			default: ''
		},
		scrollbarVisibility: {
			type: String,
			default: 'hide-horizontal-scrollbar'
		},
		autoplay: {
			type: Boolean,
			default: false
		},
		autoplayInterval: {
			type: Number,
			default: 4000
		},
		gradientColorFrom: {
			type: String,
			default: 'from-white'
		},
		gradientWidth: {
			type: Number,
			default: 20
		},
		fadeUseRowItemHeight: {
			type: Boolean,
			default: false
		},
		arrowOffsetClasses: {
			type: String,
			default: ''
		},
		rightArrowPosition: {
			type: String,
			default: '-right-12'
		},
		leftArrowPosition: {
			type: String,
			default: 'left-0'
		},
		initialSlide: {
			type: Number,
			default: 1
		},
		noBulletRingOffset: {
			type: Boolean,
			default: false
		},
		disableAutoscroll: {
			type: Boolean,
			default: false
		},
		itemWidth: {
			type: Number,
			default: 0
		},
		emitScroll: {
			type: Boolean,
			default: false
		},
		forceBulletAction: {
			type: Boolean,
			default: false
		},
		scrollBehavior: {
			type: String,
			default: 'smooth',
			validator: (value) => {
				return [ 'smooth', 'auto' ].includes(value)
			}
		},
		loading: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			arrowRight: require('@/assets/icons/arrow-right.svg'),
			activeSlide: 0,
			timerId: null,
			paused: false,
			isMounted: false,
			CAROUSEL_ITEM
		}
	},
	computed: {
		combinedScrollClasses() {
			return [ this.sideScrollerClass, this.scrollbarVisibility ].join(' ')
		},
		sideScrollerRef() {
			return this.$refs?.carousel?.$refs?.[SIDE_SCROLLER]
		},
		slideCount() {
			return Math.ceil(this.itemCount / this.itemsPerSlide)
		},
		xPaddingOffset() {
			return this.itemsPerSlide === 1 ? 1 : this.getContainerWidth() / (this.getItemWidth() * this.slideCount)
		},
		hasMultipleSlides() {
			return this.slideCount > 1
		},
		showLeftArrow() {
			return this.activeSlide > 0
		},
		showRightArrow() {
			return this.activeSlide + 2 <= this.slideCount
		}
	},
	watch: {
		$route: {
			handler() {
				Bugsnag.leaveBreadcrumb(`route changed: ${this.$route}`)
				this.setSlide(this.initialSlide)
			},
			deep: true
		},
		initialSlide: {
			immediate: true,
			handler(newValue) {
				if (newValue > 1 && this.isMounted) {
					this.setSlide(this.initialSlide)
				}
			}
		},
		isMounted: {
			immediate: true,
			handler(newValue) {
				if (newValue) {
					this.setSlide(this.initialSlide)
				}
			}
		}
	},
	mounted() {
		this.isMounted = true
		this.startAutoplay()
	},
	unmounted() {
		this.isMounted = false
		clearInterval(this.timerId)
	},
	methods: {
		getContainerWidth() {
			return this.$refs?.carousel?.$el?.offsetWidth
		},
		getScrollWidth() {
			return this.$refs?.carousel?.$refs?.[SIDE_SCROLLER]?.scrollWidth
		},
		getItemWidth() {
			if (this.isMounted) {
				if (this.itemWidth) return this.itemWidth
				if (this.itemsPerSlide === 1) { // use full width if only one item per slide
					return this.getContainerWidth()
				} else {
					return this.$refs?.carousel?.$children?.[1]?.$refs?.[CAROUSEL_ITEM]?.offsetWidth || this.$refs?.carousel?.$children?.[1]?.$refs?.[CAROUSEL_ITEM]?.$el?.offsetWidth
				}
			}
			return 0
		},
		setSlide(index, override) {
			this.pauseAutoplay()
			this.activeSlide = index - 1
			this.handleScroll(override)
			this.resumeAutoplay()
		},
		previous(override) {
			this.pauseAutoplay()
			this.activeSlide = this.showLeftArrow ? this.activeSlide-- : this.slideCount - 1
			this.handleScroll(override)
			this.resumeAutoplay()
		},
		next(override) {
			this.pauseAutoplay()
			this.activeSlide = this.showRightArrow ? this.activeSlide++ : 0
			this.handleScroll(override)
			this.resumeAutoplay()
		},
		scrollRight() {
			this.pauseAutoplay()
			this.activeSlide++
			this.handleScroll()
			this.resumeAutoplay()
		},
		scrollLeft() {
			this.pauseAutoplay()
			this.activeSlide--
			this.handleScroll()
			this.resumeAutoplay()
		},
		handleScroll(override) {
			if (this.emitScroll && !override) {
				this.$emit('handle-scroll', this.activeSlide + 1)
			} else {
				if (!this?.sideScrollerRef?.scrollTo) {
					logError(new Error(`No carousel [this.sideScrollerRef]: ${this?.sideScrollerRef}`))
					return
				}
				if (this.disableAutoscroll) return
				const leftEdge = (this.getItemWidth() * this.itemsPerSlide) * this.activeSlide
				this.checkScrollBounds(leftEdge)
				this.sideScrollerRef.scrollTo({
					top: 0,
					left: leftEdge * this.xPaddingOffset,
					behavior: this.scrollBehavior
				})
			}
		},
		checkScrollBounds(leftEdge) {
			if (leftEdge < 0) {
				this.activeSlide = 0
			} else if (leftEdge > this.getScrollWidth()) {
				this.activeSlide = this.slideCount - 1
			}
		},
		moveSlide() {
			if (!this.showRightArrow) {
				this.activeSlide = 0
				this.handleScroll()
			} else {
				this.activeSlide++
				this.handleScroll()
			}
		},
		startAutoplay() {
			if (this.autoplay) this.timerId = setInterval(this.moveSlide, this.autoplayInterval)
		},
		pauseAutoplay() {
			if (this.autoplay) {
				this.paused = true
				clearInterval(this.timerId)
			}
		},
		resumeAutoplay() {
			if (this.autoplay) {
				this.paused = false
				this.startAutoplay()
			}
		}
	}
}
</script>
