var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"grid w-full grid-cols-1 gap-16 py-16 text-xl font-bold text-center"},[_c('div',{staticClass:"w-full text-gray-300"},[_vm._v("\n\t\t\tLoading...\n\t\t")]),_vm._v(" "),_c('LoadingSpinner',{staticClass:"w-1/4 mx-auto text-gray-300 md:w-1/12"})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_vm._t("header"),_vm._v(" "),_c('div',{staticClass:"relative w-full"},[_c('Transition',{attrs:{"name":"fade"}},[_vm._t("previous",function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLeftArrow && _vm.hasMultipleSlides),expression:"showLeftArrow && hasMultipleSlides"}],class:[
							'absolute z-20 transform -translate-x-1/2 -translate-y-1/2 top-1/2',
							_vm.arrowVisibility,
							_vm.leftArrowPosition
						]},[_c('button',{staticClass:"transition-all duration-100 ease-in-out border-0 border-none rounded-full",class:[
								_vm.arrowOffsetClasses,
								_vm.arrowClasses,
								_vm.arrowPreviousOffset
							],on:{"click":function($event){return _vm.scrollLeft()}}},[_c('span',{class:[
									_vm.arrowSizeClasses,
									_vm.arrowColor,
									'flex content-center justify-center text-4xl font-bold text-center align-middle'
								]},[_c('img',{staticClass:"my-auto transform rotate-180 h-2/3",attrs:{"src":_vm.arrowRight,"height":20,"width":20,"alt":"Previous Arrow"}})])])])]})],2),_vm._v(" "),_c('Transition',{attrs:{"name":"fade"}},[_vm._t("next",function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRightArrow && _vm.hasMultipleSlides),expression:"showRightArrow && hasMultipleSlides"}],class:[
							'absolute z-20 transform -translate-x-1/2 -translate-y-1/2 top-1/2',
							_vm.arrowVisibility,
							_vm.rightArrowPosition
						]},[_c('button',{staticClass:"transition-all duration-100 ease-in-out border-0 border-none rounded-full",class:[
								_vm.arrowClasses,
								_vm.arrowOffsetClasses,
								_vm.arrowNextOffset
							],on:{"click":function($event){return _vm.scrollRight()}}},[_c('span',{class:[
									_vm.arrowSizeClasses,
									_vm.arrowColor,
									'flex content-center justify-center text-4xl font-bold text-center align-middle'
								]},[_c('img',{staticClass:"my-auto h-3/4",attrs:{"src":_vm.arrowRight,"height":20,"width":20,"alt":"Next Arrow"}})])])])]})],2),_vm._v(" "),_c('WwSideScroller',{ref:"carousel",attrs:{"fade-visibility":_vm.fadeVisibility,"item-spacing":_vm.itemSpacing,"scroller-class":_vm.combinedScrollClasses,"gradient-color-from":_vm.gradientColorFrom,"gradient-width":_vm.gradientWidth,"fade-use-row-item-height":_vm.fadeUseRowItemHeight},nativeOn:{"mouseover":function($event){return _vm.pauseAutoplay.apply(null, arguments)},"mouseout":function($event){return _vm.resumeAutoplay.apply(null, arguments)}}},[_vm._t("slides")],2),_vm._v(" "),(!_vm.hideFooter)?_vm._t("footer",function(){return [(_vm.hasMultipleSlides)?_c('div',{staticClass:"absolute flex justify-center w-full space-x-3 transform -translate-x-1/2 -bottom-8 xl:flex left-1/2",class:_vm.footerClass},_vm._l((_vm.slideCount),function(index){return _c('button',{key:'dot-' + index,class:[
							'flex justify-center border-none rounded-full flex-nowrap transition-all duration-300 ease-in-out focus:rounded-full',
							{ 'ring-offset-2': !_vm.noBulletRingOffset },
							_vm.bulletOuterClasses
						],attrs:{"title":"Slide Control Button"},on:{"click":function($event){return _vm.setSlide(index, _vm.forceBulletAction)},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;return _vm.previous(_vm.forceBulletAction)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 2)return null;return _vm.next(_vm.forceBulletAction)}]}},[_c('span',{staticClass:"relative w-4 h-4 rounded-full"},[_c('span',{class:[
									index - 1 === _vm.activeSlide ? ' bg-green-500' : 'bg-gray-300',
									{ 'ring-offset-2': !_vm.noBulletRingOffset },
									_vm.bulletInnerClasses,
									'absolute top-0 left-0 z-10 w-4 h-4 border-none rounded-full transform transition-all duration-300 ease-in-out ring-transparent ring-2'
								]}),_vm._v(" "),_c('span',{class:[
									index - 1 === _vm.activeSlide ? ' bg-gray-300 scale-0' : 'bg-gray-300 scale-100',
									{ 'ring-offset-2': !_vm.noBulletRingOffset },
									_vm.bulletInnerClasses,
									'absolute top-0 left-0 z-20 w-4 h-4 border-none rounded-full transform transition-all duration-300 ease-in-out ring-transparent ring-2'
								]})])])}),0):_vm._e()]},{"setSlide":_vm.setSlide,"previous":_vm.previous,"next":_vm.next}):_vm._e()],2)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }